import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { COLOR_COUNT_0 } from '../colors';
import { formatCountValue } from '../utils';

import { AreaChart, MicroBarChart } from '../components/charts';
import { Row333333 } from '../components/grid';
import { CountMetric, SearchMetric } from '../components/metrics';
import { Paper, PaperPadding, PaperTitle } from '../components/papers';
import { Ranking, RankingTable } from '../components/rankings';
import { AppLayout } from '../layout';

/**
 *
 */
export function Category(props) {
  const { appData, charts, currentRanking, details, metrics } = props.pageContext;

  return (
    <AppLayout data={appData} title={`Most In-Demand ${details.name_display}`}>
      <Helmet>
        <title>{details.name_display} | Technologies | StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <Row333333>
        <SearchMetric value={`${details.technologies.length} Technologies`} label="Searched" />

        <CountMetric value={`${formatCountValue(metrics.currentListingCount)} Listings`} label="Current" />

        <CountMetric value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`} label="Historic" />
      </Row333333>

      <Paper>
        <PaperTitle>Job Listings Analyzed</PaperTitle>
        <PaperPadding>
          <MicroBarChart
            chartId="micro_bar_chart_categories_listing_count"
            color={COLOR_COUNT_0}
            data={charts.microBarListingCount}
          />
        </PaperPadding>
      </Paper>

      <Paper>
        <PaperTitle>Job Ranking for {details.name_display}</PaperTitle>

        <Ranking>
          <RankingTable data={currentRanking} parentPath={`technologies/${details.name}`} />
        </Ranking>
      </Paper>

      <Paper>
        <PaperTitle>Demand for {details.name_display} Over Time</PaperTitle>
        <PaperPadding>
          <AreaChart chartId={'area_chart_market_share'} data={charts.areaTechnologies} ranking={currentRanking} />
        </PaperPadding>
      </Paper>
    </AppLayout>
  );
}

Category.propTypes = {
  pageContext: PropTypes.shape({}).isRequired
};

export default Category;

//   <div className="widget-technology-metrics">
//     <span className="widget-technology-metrics-label">Gained the Most Ranks</span>
//     {metrics.currentRankWinners.map((item) => (
//       <div key={item.id}>
//         <span className="widget-technology-metrics-value">
//           <Link to={`/technologies/${details.name}/${item.name}`}>{item.name_display}</Link>
//         </span>
//         <span className="widget-technology-metrics-subtext">{`+${item.rank_movement.toLocaleString()} Ranks Gained`}</span>
//       </div>
//     ))}
//   </div>
//
//   <div className="widget-technology-metrics">
//     <span className="widget-technology-metrics-label">Lost the Most Ranks</span>
//     {metrics.currentRankLosers.map((item) => (
//       <div key={item.id}>
//         <span className="widget-technology-metrics-value">
//           <Link to={`/technologies/${details.name}/${item.name}`}>{item.name_display}</Link>
//         </span>
//         <span className="widget-technology-metrics-subtext">{`${item.rank_movement.toLocaleString()} Ranks Lost`}</span>
//       </div>
//     ))}
//   </div>
