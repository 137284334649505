import styled from 'styled-components';

/**
 *
 */
const Row = styled.div`
  overflow: hidden;
  display: grid;
`;

/**
 *
 */
export const Row5050 = styled(Row)`
  @media all and (min-width: 1024px) {
    grid-template-columns: 50% 50%;
  }
`;

/**
 *
 */
export const Row5050Chord = styled(Row)`
  @media all and (min-width: 1366px) {
    grid-template-columns: 50% 50%;
  }
`;

/**
 *
 */
export const Row333333 = styled(Row)`
  @media all and (min-width: 768px) {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
`;
