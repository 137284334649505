import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartLine, faSearch } from '@fortawesome/free-solid-svg-icons';

import { COLOR_COUNT_0, COLOR_FONT_0, COLOR_FONT_1, COLOR_RANK_0, COLOR_SEARCH_0, COLOR_SURFACE_1 } from '../colors';

/**
 *
 */
const Metric = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: 8px 0 0 8px;
  background: ${COLOR_SURFACE_1};
  border-radius: 2px;

  @media all and (min-width: 768px) {
    margin: 16px 0 0 16px;
  }

  @media all and (min-width: 1440px) {
    margin: 24px 0 0 24px;
  }
`;

/**
 *
 */
const MetricIcon = styled.div`
  width: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 32px;
`;

/**
 *
 */
const CountMetricIcon = styled(MetricIcon)`
  background: ${COLOR_COUNT_0};
`;

/**
 *
 */
const RankMetricIcon = styled(MetricIcon)`
  background: ${COLOR_RANK_0};
`;

/**
 *
 */
const SearchMetricIcon = styled(MetricIcon)`
  background: ${COLOR_SEARCH_0};
`;

/**
 *
 */
const MetricBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px 8px 12px;

  @media all and (min-width: 1440px) {
    padding: 12px 24px 8px 24px;
  }
`;

/**
 *
 */
const MetricValue = styled.span`
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;

  @media all and (min-width: 768px) {
    font-size: 16px;
  }

  @media all and (min-width: 1024px) {
    font-size: 20px;
  }
`;

/**
 *
 */
const MetricLabel = styled.span`
  line-height: 28px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${COLOR_FONT_1};
`;

/**
 *
 */
const BasicMetricBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px 8px 0;

  @media all and (min-width: 1440px) {
    padding: 12px 24px 8px 24px;
  }
`;

/**
 *
 */
export function BasicMetric(props) {
  const color = typeof props.color !== 'undefined' ? props.color : COLOR_FONT_0;
  const icon = typeof props.icon !== 'undefined' ? <MetricIcon style={{ color }}>{props.icon}</MetricIcon> : '';

  return (
    <Metric>
      {icon}
      <BasicMetricBody>
        <MetricValue>{props.value}</MetricValue>
        <MetricLabel>{props.label}</MetricLabel>
      </BasicMetricBody>
    </Metric>
  );
}
BasicMetric.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  color: PropTypes.string
};

/**
 *
 */
export function CountMetric(props) {
  return (
    <Metric>
      <CountMetricIcon>
        <FontAwesomeIcon icon={faChartBar} />
      </CountMetricIcon>
      <MetricBody>
        <MetricValue>{props.value}</MetricValue>
        <MetricLabel>{props.label}</MetricLabel>
      </MetricBody>
    </Metric>
  );
}
CountMetric.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

/**
 *
 */
export function RankMetric(props) {
  return (
    <Metric>
      <RankMetricIcon>
        <FontAwesomeIcon icon={faChartLine} />
      </RankMetricIcon>
      <MetricBody>
        <MetricValue>{props.value}</MetricValue>
        <MetricLabel>{props.label}</MetricLabel>
      </MetricBody>
    </Metric>
  );
}
RankMetric.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

/**
 *
 */
export function SearchMetric(props) {
  return (
    <Metric>
      <SearchMetricIcon>
        <FontAwesomeIcon icon={faSearch} />
      </SearchMetricIcon>
      <MetricBody>
        <MetricValue>{props.value}</MetricValue>
        <MetricLabel>{props.label}</MetricLabel>
      </MetricBody>
    </Metric>
  );
}
SearchMetric.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
