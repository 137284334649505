import styled from 'styled-components';
import { Link } from 'gatsby';

import { COLOR_FONT_1, COLOR_LINK_0 } from '../../colors';

/**
 *
 */
export const RankingLink = styled(Link)`
  display: block;
  background-color: ${COLOR_LINK_0};
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  color: ${COLOR_FONT_1};

  &:visited {
    color: ${COLOR_FONT_1};
  }

  &:hover {
    color: ${COLOR_LINK_0};
  }

  @media all and (min-width: 768px) {
    background-color: transparent;
  }
`;
