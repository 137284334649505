import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import {
  COLOR_FONT_0,
  COLOR_FONT_1,
  COLOR_LINK_0,
  COLOR_GAIN_0,
  COLOR_LOSS_0,
  COLOR_SURFACE_1_RELIEF
} from '../../colors';
import { formatPercent } from '../../utils';

// ---------------------------------------------------------------------------
// TABLE
// ---------------------------------------------------------------------------

/**
 *
 */
const Cell = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${COLOR_FONT_1};
`;

/**
 *
 */
const StyledRankCell = styled(Cell)`
  flex-basis: 15%;
  text-align: center;

  @media all and (min-width: 768px) {
    flex-basis: 7%;
  }

  @media all and (min-width: 1024px) {
    flex-basis: 5%;
  }
`;

function RankCell(props) {
  return <StyledRankCell>{props.value ?? '-'}</StyledRankCell>;
}

/**
 *
 */
const StyledRankMovementCell = styled(Cell)`
  display: none;

  @media all and (min-width: 1024px) {
    display: block;
    flex-basis: 5%;
    text-align: right;
  }
`;

function RankMovementCell(props) {
  if (props.value > 0) {
    return (
      <StyledRankMovementCell style={{ color: COLOR_GAIN_0 }}>{props.value.toLocaleString()}</StyledRankMovementCell>
    );
  } else if (props.value < 0) {
    return (
      <StyledRankMovementCell style={{ color: COLOR_LOSS_0 }}>{props.value.toLocaleString()}</StyledRankMovementCell>
    );
  } else {
    return <StyledRankMovementCell style={{ color: COLOR_FONT_1 }}>-</StyledRankMovementCell>;
  }
}

/**
 *
 */
const StyledMovementArrowCell = styled(Cell)`
  display: none;

  @media all and (min-width: 768px) {
    display: block;
    flex-basis: 4%;
    text-align: center;
  }

  @media all and (min-width: 1024px) {
    flex-basis: 3%;
  }
`;

function MovementArrowCell(props) {
  if (props.value > 0) {
    return (
      <StyledMovementArrowCell>
        <FontAwesomeIcon icon={faArrowUp} style={{ color: COLOR_GAIN_0 }} />
      </StyledMovementArrowCell>
    );
  } else if (props.value < 0) {
    return (
      <StyledMovementArrowCell>
        <FontAwesomeIcon icon={faArrowDown} style={{ color: COLOR_LOSS_0 }} />
      </StyledMovementArrowCell>
    );
  } else {
    return <StyledMovementArrowCell>-</StyledMovementArrowCell>;
  }
}

/**
 *
 */
const NameCell = styled(Cell)`
  flex-basis: 60%;
  margin-left: 2%;
  text-align: left;

  a,
  a:visited {
    color: ${COLOR_FONT_0};

    &:hover {
      color: ${COLOR_LINK_0};
    }
  }

  @media all and (min-width: 768px) {
    flex-basis: 52%;
  }

  @media all and (min-width: 1024px) {
    flex-basis: 31%;
    margin-left: 3%;
  }
`;

/**
 *
 */
const StyledCountCell = styled(Cell)`
  flex-basis: 23%;
  text-align: right;

  @media all and (min-width: 768px) {
    flex-basis: 13%;
    margin-right: 3%;
  }

  @media all and (min-width: 1024px) {
    flex-basis: 12%;
    margin-right: 2%;
  }
`;

function CountCell(props) {
  return <StyledCountCell>{props.value.toLocaleString()}</StyledCountCell>;
}

/**
 *
 */
const StyledCountMovementCell = styled(Cell)`
  display: none;

  @media all and (min-width: 1024px) {
    display: block;
    flex-basis: 8%;
    text-align: right;
  }
`;

function CountMovementCell(props) {
  if (props.value > 0) {
    return (
      <StyledCountMovementCell style={{ color: COLOR_GAIN_0 }}>{props.value.toLocaleString()}</StyledCountMovementCell>
    );
  } else if (props.value < 0) {
    return (
      <StyledCountMovementCell style={{ color: COLOR_LOSS_0 }}>{props.value.toLocaleString()}</StyledCountMovementCell>
    );
  } else {
    return <StyledCountMovementCell style={{ color: COLOR_FONT_1 }}>-</StyledCountMovementCell>;
  }
}

/**
 *
 */
const StyledPercentCell = styled(Cell)`
  display: none;

  @media all and (min-width: 768px) {
    display: block;
    flex-basis: 13%;
    margin-right: 3%;
    text-align: right;
  }

  @media all and (min-width: 1024px) {
    flex-basis: 12%;
    margin-right: 2%;
  }
`;

function PercentCell(props) {
  return <StyledPercentCell>{formatPercent(props.value)}</StyledPercentCell>;
}

/**
 *
 */
const StyledPercentMovementCell = styled(Cell)`
  display: none;

  @media all and (min-width: 1024px) {
    display: block;
    flex-basis: 8%;
    text-align: right;
  }
`;

function PercentMovementCell(props) {
  if (props.value > 0) {
    return (
      <StyledPercentMovementCell style={{ color: COLOR_GAIN_0 }}>
        {formatPercent(props.value)}
      </StyledPercentMovementCell>
    );
  } else if (props.value < 0) {
    return (
      <StyledPercentMovementCell style={{ color: COLOR_LOSS_0 }}>
        {formatPercent(props.value)}
      </StyledPercentMovementCell>
    );
  } else {
    return <StyledPercentMovementCell style={{ color: COLOR_FONT_1 }}>-</StyledPercentMovementCell>;
  }
}

const Row = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  border-bottom: 1px solid ${COLOR_SURFACE_1_RELIEF};
  line-height: 48px;
  font-weight: 400;

  @media all and (min-width: 768px) {
    padding: 0 16px;
  }
`;

/**
 *
 */
const StyledHeaderRow = styled(Row)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`;

export function HeaderRow() {
  return (
    <StyledHeaderRow>
      <StyledRankCell>Rank</StyledRankCell>
      <StyledMovementArrowCell></StyledMovementArrowCell>
      <StyledRankMovementCell>&Delta;</StyledRankMovementCell>
      <NameCell>Name</NameCell>
      <StyledCountCell>Jobs</StyledCountCell>
      <StyledMovementArrowCell></StyledMovementArrowCell>
      <StyledCountMovementCell>&Delta;</StyledCountMovementCell>
      <StyledPercentMovementCell>&Delta;%</StyledPercentMovementCell>
      <StyledPercentCell>Share</StyledPercentCell>
      <StyledMovementArrowCell></StyledMovementArrowCell>
      <StyledPercentMovementCell>&Delta;</StyledPercentMovementCell>
    </StyledHeaderRow>
  );
}

/**
 *
 */
const StyledDataRow = styled(Row)`
  &:hover {
    background-color: ${COLOR_SURFACE_1_RELIEF};

    a,
    a:visited {
      color: ${COLOR_LINK_0};
    }
  }
`;

export function DataRow(props) {
  return (
    <StyledDataRow>
      <RankCell value={props.data.rank} />
      <MovementArrowCell value={props.data.rank_movement} />
      <RankMovementCell value={props.data.rank_movement} />
      <NameCell>
        <Link to={props.data.linkTo}>{props.data.linkText}</Link>
      </NameCell>
      <CountCell value={props.data.count} />
      <MovementArrowCell value={props.data.count_movement} />
      <CountMovementCell value={props.data.count_movement} />
      <PercentMovementCell value={props.data.count_movement_percent} />
      <PercentCell value={props.data.percent} />
      <MovementArrowCell value={props.data.percent_movement} />
      <PercentMovementCell value={props.data.percent_movement} />
    </StyledDataRow>
  );
}

// ---------------------------------------------------------------------------
// RANKING
// ---------------------------------------------------------------------------

/**
 *
 */
export function RankingTable(props) {
  const { data, parentPath } = props;

  return (
    <>
      <HeaderRow />
      {data.map((item) => (
        <DataRow
          key={item.id}
          data={{ ...item, linkTo: `/${parentPath}/${item.name}/`, linkText: item.name_display }}
        />
      ))}
    </>
  );
}

/**
 *
 */
export const Ranking = styled.div``;
